<template>
  <v-combobox
    v-model="bankAccountSelection"
    :items="bank_accounts"
    item-text="bank_account_name"
    item-value="bank_account_name"
    :return-object="false"
    outlined
    dense
    label="Select Bank Account"
    append-icon="mdi-filter-variant"
    @change="passFilteredAccounts"
    @click:clear="passClearFilter"
    clearable
  ></v-combobox>
</template>
<script>
export default {
  name: "BankSatementsAccountsFilter",
  data() {
    return {};
  },
  props: ["value", "bank_accounts"],
  computed: {
    bankAccountSelection: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    passFilteredAccounts() {
      this.$emit("triggerAccountsFilter", this.bankAccountSelection);
    },
    passClearFilter() {
      this.$emit("triggerClearAccountsFilter");
    },
  },
};
</script>
